// extracted by mini-css-extract-plugin
export var author = "blog-module--author--WAdjF";
export var authordetails = "blog-module--authordetails--S8c2c";
export var blogContent_inner = "blog-module--blogContent_inner--M2A03";
export var blog_banner = "blog-module--blog_banner--gDXUj";
export var blog_banner_bg_txt = "blog-module--blog_banner_bg_txt--a2iMK";
export var blog_banner_content = "blog-module--blog_banner_content--cpl7O";
export var blog_banner_content_header = "blog-module--blog_banner_content_header--Xihvv";
export var blog_banner_content_text = "blog-module--blog_banner_content_text--g1S5U";
export var blog_banner_wrapper = "blog-module--blog_banner_wrapper--0QPFZ";
export var blog_content = "blog-module--blog_content--jRWL1";
export var blog_date = "blog-module--blog_date--QtHsl";
export var blog_inner = "blog-module--blog_inner--AUh9g";
export var blog_inner_wrapper = "blog-module--blog_inner_wrapper--x8gmu";
export var blog_nav_link = "blog-module--blog_nav_link--XjjNh";
export var blog_nav_link_mobile = "blog-module--blog_nav_link_mobile--yWfN-";
export var blog_second_inner = "blog-module--blog_second_inner--8ogY5";
export var blog_second_wrapper = "blog-module--blog_second_wrapper--rvGLx";
export var blog_subheader = "blog-module--blog_subheader--Kd7aA";
export var blog_subtopic = "blog-module--blog_subtopic--IaPMv";
export var blog_text = "blog-module--blog_text--OSlV+";
export var blog_third_inner = "blog-module--blog_third_inner--bOTMV";
export var blog_third_wrapper = "blog-module--blog_third_wrapper--XinVy";
export var blog_topic = "blog-module--blog_topic---Dd23";
export var blog_wrapper = "blog-module--blog_wrapper--fcdFA";
export var blogcontent = "blog-module--blogcontent--kstCX";
export var blogcontent_mobile_link_carousel = "blog-module--blogcontent_mobile_link_carousel--ZnN88";
export var blogcontent_mobile_link_carousel_wrapper = "blog-module--blogcontent_mobile_link_carousel_wrapper--cBukp";
export var mobile_blogContent_content_wrapper = "blog-module--mobile_blogContent_content_wrapper--xP2-b";
export var mobile_blogContent_details = "blog-module--mobile_blogContent_details--W0wxm";
export var mobile_blogContent_wrapper = "blog-module--mobile_blogContent_wrapper--40ysN";
export var mobile_blog_author_details = "blog-module--mobile_blog_author_details--P58ks";
export var mobile_blog_author_details_inner = "blog-module--mobile_blog_author_details_inner--yyXgl";
export var mobile_blog_content_inner = "blog-module--mobile_blog_content_inner--fNlgv";
export var mobile_blog_content_inner_content = "blog-module--mobile_blog_content_inner_content--wrN-W";
export var mobile_blog_header_content = "blog-module--mobile_blog_header_content--kvI+j";
export var mobile_blog_header_wrapper = "blog-module--mobile_blog_header_wrapper---59mI";
export var mobile_blogcontent_wrapper = "blog-module--mobile_blogcontent_wrapper--DVVwP";
export var mobile_seeAll = "blog-module--mobile_seeAll--cKbBH";
export var seeAll = "blog-module--seeAll--emqag";
export var viewmore = "blog-module--viewmore--Q-mKx";